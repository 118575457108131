import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-wichita-falls-texas.png'
import image0 from "../../images/cities/scale-model-of-professional-wrestling-hall-of-fame-&-museum-in-wichita-falls-texas.png"
import image1 from "../../images/cities/scale-model-of-museum-of-north-texas-history-in-wichita-falls-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Wichita Falls'
            state='Texas'
            image={image}
            lat='33.9137085'
            lon='-98.4933873'
            attractions={ [{"name": "Professional Wrestling Hall of Fame & Museum", "vicinity": "712 8th St #100, Wichita Falls", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.9123245, "lng": -98.49170950000001}, {"name": "Museum of North Texas History", "vicinity": "720 Indiana Ave, Wichita Falls", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.9129049, "lng": -98.49138649999998}] }
            attractionImages={ {"Professional Wrestling Hall of Fame & Museum":image0,"Museum of North Texas History":image1,} }
       />);
  }
}